import { readonly, writable } from 'svelte/store';
import { browser } from '$app/environment';
import posthog from 'posthog-js';

export const createFeatureFlagStore = (flagName: string) => {
	const data = writable(false);

	if (browser) {
		posthog.onFeatureFlags(() => {
			data.set(posthog.isFeatureEnabled(flagName) || false);
		});
	}

	return readonly(data);
};
