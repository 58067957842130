<script lang="ts">
	import { classes } from '@thearc-hq/ui-kit/utils';
	import { createEventDispatcher, onMount } from 'svelte';
	import { browser } from '$app/environment';
	import { createFeatureFlagStore } from '$lib/store/featureFlag.store';
	import { onVisible } from '$lib/utils/intersection';

	export let scrollerRef: HTMLElement | undefined = undefined;
	export let title: string | undefined = undefined;
	export let showControls: boolean = false;
	let className = '';
	export { className as class };

	const dispatch = createEventDispatcher();

	const childWidth = () => scrollerRef?.children?.[0]?.getBoundingClientRect().width ?? 0;

	const currentElementSize = () => {
		const children = Array.from(scrollerRef?.children ?? []);
		if (children.length > 0) {
			return (
				children.reduce((max, child) => {
					const children = child.children;
					if (children.length === 4) {
						// So this is the most unstable thing I've ever written, but it works cause the dom structure of products cards hopefully never change
						const combinedHeight =
							children[0].getBoundingClientRect().height +
							children[1].getBoundingClientRect().height +
							children[2].getBoundingClientRect().height;
						return Math.max(max, combinedHeight);
					}
					return Math.max(max, child.getBoundingClientRect().height);
				}, 0) + 32
			);
		}
		return 420;
	};

	let hasNext = true;
	let hasPrev = false;
	let hasChildren = true;
	let childrenSize = 420;

	const shouldSnapABTest = createFeatureFlagStore('shouldSnapABTest');

	const evaluateControls = () => {
		setTimeout(() => {
			if (!scrollerRef) {
				return;
			}
			hasNext = scrollerRef.scrollLeft + scrollerRef.clientWidth < scrollerRef.scrollWidth - 40;
			hasPrev = scrollerRef.scrollLeft > 50;
			showControls =
				(scrollerRef?.children?.length ?? 0) * childWidth() >= scrollerRef?.clientWidth;
		}, 300);
	};

	const next = () => {
		if (!scrollerRef) {
			return;
		}
		scrollerRef.scrollBy({
			left: childWidth(),
			behavior: 'smooth'
		});
		evaluateControls();
	};

	const prev = () => {
		if (!scrollerRef) {
			return;
		}
		scrollerRef?.scrollBy({
			left: -childWidth(),
			behavior: 'smooth'
		});
		evaluateControls();
	};

	const updateElementSize = () => {
		childrenSize = Math.max(currentElementSize(), 630) + 20;
	};

	const productCardObserver = browser ? new MutationObserver(updateElementSize) : undefined;

	const evaluateChildren = () => {
		if (!scrollerRef) {
			return;
		}
		hasChildren = (scrollerRef?.children?.length ?? 0) > 0;
		if (hasChildren) {
			productCardObserver.disconnect();
			for (const child of scrollerRef.children) {
				productCardObserver.observe(child, { childList: true });
			}
		}
	};

	onMount(() => {
		evaluateChildren();
		evaluateControls();
		const observer = new MutationObserver(evaluateChildren);
		observer.observe(scrollerRef, { childList: true });

		window.addEventListener('resize', evaluateControls);

		return () => {
			observer.disconnect();
			window.removeEventListener('resize', evaluateControls);
		};
	});
</script>

<!-- TODO: Implement the gtm tracking - gtmProductListType -->
<!-- Previously there was a e.g.: gtmProductListType={GtmProductListType.Order} -->
<div
	use:onVisible={() => {
		dispatch('visible');
	}}
	class="box-x-wrapper space-y-6 overflow-x-hidden"
>
	{#if hasChildren}
		<div class="flex justify-between">
			{#if title}
				<h2>{title}</h2>
			{:else}
				<span></span>
			{/if}
			{#if showControls}
				<div class="gap-2 flex-none hidden md:flex">
					<button
						on:click={prev}
						disabled={!hasPrev}
						class="rounded-full grid place-items-center bg-accent-primary w-8 h-8 disabled:opacity-50"
					>
						<div class="text-white i-sprenger-chevron-left w-5 h-5"></div>
					</button>
					<button
						on:click={next}
						disabled={!hasNext}
						class="rounded-full grid place-items-center bg-accent-primary w-8 h-8 disabled:opacity-50"
					>
						<div class="text-white i-sprenger-chevron-right w-5 h-5"></div>
					</button>
				</div>
			{/if}
		</div>
	{/if}
	<div class="relative" style:height={`${hasChildren ? childrenSize : 0}px`}>
		<div
			bind:this={scrollerRef}
			class={classes(
				'flex gap-4 overflow-x-auto scrollbar-hide w-full absolute h-full',
				className,
				hasChildren ? 'py-2' : '',
				$shouldSnapABTest ? 'snap-x' : 'md:snap-x'
			)}
			on:scrollend={evaluateControls}
		>
			<slot />
		</div>
	</div>
	{#if showControls && hasChildren}
		<div class="gap-2 justify-center md:hidden flex">
			<button
				on:click={prev}
				disabled={!hasPrev}
				class="rounded-full grid place-items-center bg-accent-primary w-8 h-8 disabled:opacity-50"
			>
				<div class="text-white i-sprenger-chevron-left w-5 h-5"></div>
			</button>
			<button
				on:click={next}
				disabled={!hasNext}
				class="rounded-full grid place-items-center bg-accent-primary w-8 h-8 disabled:opacity-50"
			>
				<div class="text-white i-sprenger-chevron-right w-5 h-5"></div>
			</button>
		</div>
	{/if}
</div>
